.media-component {
  .media-transcript {
    &:hover {
      color: @button-text-color-hover;
      background-color: @button-color-hover;
    }
  }
  .media-transcript-button-container {
  	padding-left: 0px;
  	padding-right: 0px;
  }
}