@duration: 0.25s;

.transition-all-colors {
	-webkit-transition: background-color @duration ease-in, color @duration ease-in, border-color @duration ease-in;
	-moz-transition: background-color @duration ease-in, color @duration ease-in, border-color @duration ease-in;
	-ms-transition: background-color @duration ease-in, color @duration ease-in, border-color @duration ease-in;
	transition: background-color @duration ease-in, color @duration ease-in, border-color @duration ease-in;
} 

.transition-color {
	-webkit-transition: color @duration ease-in;
	-moz-transition: color @duration ease-in;
	-ms-transition: color @duration ease-in;
	transition: color @duration ease-in;
}

.transition-background-color {
	-webkit-transition: background-color @duration ease-in;
	-moz-transition: background-color @duration ease-in;
	-ms-transition: background-color @duration ease-in;
	transition: background-color @duration ease-in;
}

.transition-border-color {
	-webkit-transition: border-color @duration ease-in;
	-moz-transition: border-color @duration ease-in;
	-ms-transition: border-color @duration ease-in;
	transition: border-color @duration ease-in;
}