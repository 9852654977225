// Layout
// ======

#wrapper {
    width: 100%;
    margin: auto;
    overflow-x: hidden;
    padding-top: (@navigation-padding * 2) + @icon-size;

    .ie8 & {
        max-width: @ie8-max-width;
        margin: 0 auto;
    }
}