.accordion-component {

    .accordion-item {
        margin-bottom: @item-margin-bottom;
    }

    .accordion-item-title-icon {
        .dir-rtl & {
	        left:inherit;
	        right:@item-padding-left; 
        }
    }

    .accordion-item-title {
        color: @item-text-color;
        background-color: @item-color;
        padding-top: @item-padding-top;
        padding-bottom: @item-padding-bottom;
        padding-left: (@icon-size+(@item-padding-left*2));
        .item-title;
        
        .dir-rtl & {
            padding-left:inherit;
            padding-right:(@icon-size+(@item-padding-left*2));
        }

        &.visited {
            color: @item-text-color-visited;
            background-color: @item-color-visited;
            border-color: @item-border-color-visited;

            .accordion-item-title-icon {
                color: @item-text-color-visited;
            }
        }

        &.selected {
            color: @item-text-color-selected;
            background-color: @item-color-selected;
            border-color: @item-border-color-selected;

            .accordion-item-title-icon {
                color: @item-text-color-selected;
            }
        }
        .no-touch & {
            &:hover {
                color: @item-text-color-hover;
                background-color: @item-color-hover;
                border-color: @item-border-color-hover;

                .accordion-item-title-icon {
                    color: @item-text-color-hover;
                }
            }
            &.visited:hover {
                color: @item-text-color-hover;
                background-color: @item-color-hover;
                border-color: @item-border-color-hover;

                .accordion-item-title-icon {
                    color: @item-text-color-hover;
                }
            }
            &.selected:hover {
				background-color: @item-color-hover;
			}
        }

    }

    .accordion-item-body {
        color: @item-body-text-color;
        background-color: @item-body-color;
        border: @item-border;
        border-color: @item-border-color-selected;
        border-top: 0;
    }

    .accordion-item-body-inner {
        padding-top: @item-padding-top;
        padding-bottom: @item-padding-bottom;
        padding-left: @item-padding-left;
        padding-right: @item-padding-right;
    }

}
