// Font setup
// ==========

@import url(//fonts.googleapis.com/css?family=Open+Sans:400,300,700);

@font-family: 'Open Sans', Helvetica, Arial, sans-serif;

@font-weight-light: 300;
@font-weight-regular: 400;
@font-weight-bold: 700;

@menu-title-font-size: 48px;
@page-title-font-size: 48px;
@component-title-font-size: 36px;
@item-title-font-size: 24px;
@sub-title-font-size: 18px;

@body-text-font-size: 14px;
@instruction-text-font-size: 14px;
@button-text-font-size: 18px;

@title-line-height: 120%;
@body-line-height: 140%;

@icon-size: 24px;


// Detailed Font setup
// ===================

@menu-title-font-weight: @font-weight-light;
@menu-title-line-height: @title-line-height;

@page-title-font-weight: @font-weight-light;
@page-title-line-height: @title-line-height;

@component-title-font-weight: @font-weight-light;
@component-title-line-height: @title-line-height;

@item-title-font-weight: @font-weight-light;
@item-title-line-height: @title-line-height;

@sub-title-font-weight: @font-weight-light;
@sub-title-line-height: @title-line-height;

@body-text-font-weight: @font-weight-regular;
@body-text-line-height: @body-line-height;

@instruction-text-font-weight: @font-weight-bold;
@instruction-text-line-height: @body-line-height;

@button-text-font-weight: @font-weight-light;
@button-text-line-height: @body-line-height;