// Drawer styling
// ==============

.drawer {
    background-color: @drawer-color;
    color: @drawer-item-text-color;

    .drawer-toolbar {
        position: absolute;
        width:100%;
        top:0;
        border-bottom: 1px solid @drawer-border-color;
    }

    .drawer-toolbar .icon {
        color: @drawer-icon-color;
        padding: @navigation-padding;
        .transition-color;

        .no-touch &:hover {
            color: @drawer-icon-color-hover;
        }
    }
    
    .drawer-holder {
        padding-top: ((@navigation-padding * 2) + @icon-size + 1);
    }

    .drawer-inner {
        border-left: 5px solid @drawer-border-color;

        .dir-rtl & {
            border-left: inherit;
            border-right: 5px solid @drawer-border-color;
        }
    }

    .drawer-item {
        .drawer-item-title {
            .sub-title;
        }
        
        .drawer-item-description {
        	font-size: @body-text-font-size;
        	line-height: @body-text-line-height;
        }
        
        .drawer-item-open {
            border-bottom: 1px solid @drawer-item-color;
            color: @drawer-item-text-color;
            text-decoration: none;
            width: 100%;
            
            .no-touch &:hover {
                background-color: @drawer-item-color-hover;
                color: @drawer-item-text-color-hover;
            }
        }
    }

    .drawer-item-open {
        padding: @drawer-item-padding-top @drawer-item-padding-right @drawer-item-padding-bottom @drawer-item-padding-left;
        .transition-background-color;

        //stops rollover of drawar items on touch devices    
        .no-touch &:hover {
             background-color: @drawer-color;
       }
    }
}
