.article.article-noheader .article-inner {
	padding-top: 0;
	background-image: none;
	background-position: 0% 0%;
	background-repeat: repeat;
}

.results-retry-button {
	margin: @button-margin-top 0 @button-margin-bottom;
}

.assessmentResults-component.visibility-hidden {
	display: none;
}
