.gmcq-component {
    &.gmcq-column-layout {
        .gmcq-widget {
            // Font size set to 0 so there's no gap between items
            font-size: 0;
            text-align: center;
        }

        .gmcq-item {
            display: inline-block;
            float: none;
            vertical-align: top;

            .dir-rtl & {
                float: none;
            }

            &.even,
            &.odd {
                label {
                    margin: 2.5%;

                    .dir-rtl & {
                        margin: 2.5%;
                    }
                }
            }
        }

        .gmcq-item-inner {
            font-size: @body-text-font-size;
            text-align: left;

            .dir-rtl & {
                text-align: right;
            }
        }
    }
	
    .gmcq-item {
        position: relative;
        width: 50%;
        margin-bottom: @item-margin-bottom;
        float: left;
        .dir-rtl & {
            float: right;
        }

        &.even {
            label {
                margin-left: 5%;
                .dir-rtl & {
                    margin-left: inherit;
                    margin-right: 5%;
                }
            }
        }

        &.odd {
            label {
                margin-right: 5%;
                .dir-rtl & {
                    margin-right: inherit;
                    margin-left: 5%;
                }
            }
        }

        @media all and (max-width:760px) {
            width: 100%;
            &.even, 
            &.odd {
                label {
                    margin-left: 0%;
                    margin-right: 0%;
                }
            }
        }
    }

    .gmcq-item label {
        color: @item-text-color;
        display: block;
        border: @item-border;
        margin-bottom: @item-margin-bottom;
        position: relative;
        z-index: 1;
        background-color: @item-color;
        overflow: hidden;
        cursor: pointer;

        img {
            vertical-align: bottom;
        }

        .gmcq-answer-icon {
            color: @item-text-color;
            &.radio {
                width: @icon-size + 2;
                height: @icon-size + 2;
                &:before {
                    content: "\e65e";
                }
            }
            &.checkbox {
                border: 3px solid @item-text-color;
                width: 18px;
                height: 18px;
                font-size: 18px;
                font-weight: bold;
                border-radius: 2px;
                &:before {
                    content: " ";
                }
            }
        }

        &.selected {
            color: @item-text-color-selected;
            background-color: @item-color-selected;

            .gmcq-answer-icon {
                &.radio {
                    &:before {
                        content: "\e65d";
                    }
                }
                &.checkbox {
                    border: @item-border;
                    border-color: @item-text-color-selected;
                    width: 18px;
                    height: 18px;
                    font-size: 18px;
                    font-weight: bold;
                    border-radius: 2px;
                    &:before {
                        content: "\e633";
                    }
                }
            }
        }
    }

    .gmcq-item-inner {
        padding: @item-padding;
        padding-left: (@icon-size+(@item-padding-left*2));
        .dir-rtl & {
            margin-left: inherit;
            padding-right: (@icon-size+(@item-padding-left*2));
        }
    }

    .gmcq-item input {
        position: absolute;
        top: 25px;
        left: 25px;
        .dir-rtl & {
            left: inherit;
            right: 25px;
        }
    }

    .gmcq-item-checkbox {
        position: relative;
    }

    .gmcq-item-state {
        background: none;
        position: absolute;
        width: @icon-size;
        height: @icon-size;
        top: 50%;
        margin-top: -(@icon-size/2);
        left: 10px;
        z-index: 0;
        .dir-rtl & {
            left: inherit;
            right: 10px;
        }
    }

    .gmcq-correct-icon {
        color: @validation-success;
        display: none;
    }

    .gmcq-incorrect-icon {
        color: @validation-error;
        display: none;
    }

    .gmcq-widget {
        &.show-user-answer {
            .gmcq-item-icon {
                display: none;
            }
            .incorrect {
                .selected .gmcq-incorrect-icon {
                    display: block;
                }
            }
            .correct {
                .selected .gmcq-correct-icon {
                    display: block;
                }
            }
        }

        &.show-correct-answer {
            .gmcq-item-icon {
                display: none;
            }
            .incorrect .selected .gmcq-correct-icon,
            .correct .selected .gmcq-correct-icon {
                display: block;
            }
        }

        &.disabled {
            .gmcq-item label.selected.disabled {
                background-color: @item-color-selected;
                cursor: default;
            }
            .gmcq-item label.disabled {
                color: @item-text-color-disabled;
                background-color: @item-color-disabled;
                border-color: @item-border-color-disabled;
                cursor: default;
            }

            .gmcq-item.correct .selected .state {
                color: @validation-success;
            }

            .gmcq-item.incorrect .selected .state {
                color: @validation-error;
            }
        }
    }
    .graphic-attribution {
    	font-size:0.75em;
    	line-height: 1em;
    }
}

.no-touch {
    .gmcq-component {
        .gmcq-widget {
            &:not(.disabled) {
                .gmcq-item label:hover {
                    color: @item-text-color-hover;
                    background-color: @item-color-hover;
                    .gmcq-item-icon {
                        color: @item-text-color-hover;
                    }
                }
            }
        }
    }
}
