.media-component {

    .me-plugin {
        position:relative;
        width:100%;
        height:100% !important;
        object {
            height: 100% !important;
            width: 100% !important;
        }
        embed {
            height: 100% !important;
            width: 100% !important;
        }
    }

    .media-widget.video-vimeo {
        .mejs-controls {
            display: none !important;
            height: 0px !important;
        }
        .mejs-overlay-play {
            display: none !important;
        }
    }

    .mejs-container {
        background-color: transparent;
        .mejs-controls .mejs-time {
            padding-right:1px;
            padding-left:1px;
        }
    }

    .external-source {
        .mejs-container {
            position: relative;
            padding-top: 35px;
            height: 0;
            overflow: hidden;
        }
        .mejs-container iframe {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .mejs-overlay-button {
            display: none;
        }
    }

    .media-transcript {
        padding: @button-padding;
        display: block;
        color: @button-text-color;
        background-color: @button-color;
        text-decoration: none;
        &:hover {
            color: @button-text-color-hover;
            background-color: @button-color-hover;
        }
    }

    .media-inline-transcript-body-container {
        margin-top: 10px;
        display: none;
    }

    .media-inline-transcript-body {
        padding: @component-padding;
    }

    .media-transcript-button-container {
        padding:1px;
    }

    .media-external-transcript-button, .media-inline-transcript-button {
        width:100%;
        display:block;
    }
    
    // Bug Fix for touch devices to override standard hover event in mep.less L119
    // ==============================
    .mejs-overlay {
        &:hover {
            .mejs-overlay-button {
                background-position: 0 0;
            }
        }

        .no-touch &:hover {
            .mejs-overlay-button {
                background-position: 0 -100px;
            }
        }
    }
    // ==============================

    .mejs-overlay-button {
        background-image: url(assets/bigplay.svg);
    }

    .no-svg .mejs-overlay-button {
        background-image: url(assets/bigplay.png);
    }

    .mejs-overlay-loading,
    .mejs-container .mejs-controls,
    .mejs-controls .mejs-volume-button .mejs-volume-slider,
    .mejs-controls .mejs-captions-button .mejs-captions-selector,
    .mejs-captions-text,
    .mejs-controls .mejs-sourcechooser-button .mejs-sourcechooser-selector,
    .mejs-postroll-layer,
    .mejs-postroll-close,
    .mejs-controls .mejs-speed-button .mejs-speed-selector {
        background-image: url(assets/background.png);
    }

    .mejs-overlay-loading span {
        background-image: url(assets/loading.gif);
    }

    .mejs-controls .mejs-button {
        &.mejs-jump-forward-button {
            background-image: url(assets/jumpforward.png);
        }
        &.mejs-skip-back-button {
            background-image: url(assets/skipback.png);
        }
        button {
            background-image: url(assets/controls.svg);
        }
    }
    
    .accessibility.no-touch & {
        .mejs-controls .mejs-button {
            button:focus {
                outline: @focus-outline;
            }
        }
    }

    .no-svg .mejs-controls .mejs-button button {
        background-image: url(assets/controls.png);
    }

    .mejs-container-fullscreen .mejs-mediaelement, .mejs-container-fullscreen video {
        width: 100% !important;
        height: 100% !important;
        background-color: black;
    }

}


//for use when no title or body or instruction is available
.media-top-padding {
    padding-top: 4em;
}
