// Navigation PLP
// ==============

.page-level-progress-navigation {
    padding:  @navigation-padding!important; // Override defaults in extension
    margin: 5px 0;
    
    .no-touch &:hover {

        .page-level-progress-navigation-completion {
            background-color: @navigation-progress-background-color-hover;
            border-color: @navigation-progress-border-color;
        }

        .page-level-progress-navigation-bar {
            background-color: @navigation-progress-color-hover;
            border-color: @navigation-progress-color-hover;
        }

        .page-level-progress-plus-icon {
            color: @navigation-progress-color;
        }
    }
}

.page-level-progress-navigation-completion {
    height: 8px;
    background-color: @navigation-progress-background-color;
    border: 2px solid @navigation-progress-border-color;
    -webkit-border-radius: 50px; 
    -moz-border-radius: 50px;
    border-radius: 50px;
    .transition-all-colors;
}

.page-level-progress-navigation-bar {
    left: 0;
    border: 2px solid @navigation-progress-color;
    background-color: @navigation-progress-color;
    transition: all 0.3s ease-in;
    .transition-all-colors;
    
    .dir-rtl & {
        right: 0;
        left: auto;
    }
}

.page-level-progress-plus-icon {
    right: 9px;
}

// Drawer PLP
// ==========

.drawer {
    .page-level-progress-item-title {
    	background-color: @drawer-item-color;
    	color: @drawer-item-text-color;
        .transition-all-colors;

    	&.drawer-item-open.disabled {
    		background-color: @drawer-item-color-disabled;
    		color: @drawer-item-text-color-disabled;
    		font-size: @button-text-font-size;
	        font-weight: @font-weight-light;

            .no-touch &:hover {
                background-color: @drawer-item-color-disabled;
                color: @drawer-item-text-color-disabled;
                .page-level-progress-indicator {
                    background-color: transparent;
                }
            }
    	}

        .no-touch &:hover {
            background-color: @drawer-item-color-hover;
            color: @drawer-item-text-color-hover;

            .page-level-progress-indicator {
                background-color: @drawer-progress-color-hover;
            }
            .page-level-progress-indicator-incomplete {
                background-color: @drawer-item-color;
            }
        }
    }
}

.page-level-progress-item-title-inner {
	width:80%;
}

.page-level-progress-indicator {
    width: 15%;
    height: 8px;
    border: 1px solid @drawer-progress-border-color;
	background-color: transparent;
    .transition-background-color;
}

.page-level-progress-indicator-complete {

    .page-level-progress-indicator-bar {
        background-color: @drawer-progress-color;
        .transition-background-color;

        &:hover {
            background-color: @drawer-progress-color-hover;
        }
    }
}
