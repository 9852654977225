// Block styling
// =============

.block {
    background-color: @background-color;

    .block-inner {
        max-width: @device-width-large;
        margin: auto;
        padding: @block-padding-top 0 @block-padding-bottom;
        // reduce padding as screen width decreases
        @media all and (max-width: (@device-width-large + 20px)) {
            padding: (@block-padding-top / 2) 20px (@block-padding-bottom / 2);
        }
        @media all and (max-width: (@device-width-small + 20px)) {
            padding: (@block-padding-top / 3) 10px (@block-padding-bottom / 3);
        }
    }

    .block-title {
        margin-bottom: @block-title-margin-bottom;
        color: @title-color;
        text-align: left;

        .dir-rtl & {
            text-align: right;
        }
    }

    .block-body{
        margin-bottom: @block-body-margin-bottom;
    }

    .block-instruction {
        margin-bottom: @block-instruction-margin-bottom;
        .instruction-text;
    }
}
