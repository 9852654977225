// default variables, remove when core theme is implemented
@focus-outline: 3px solid orange;
@primary-color: #078292;
@device-width-medium: 520px;
@body-text-font-size: 14px;
@button-color: #000;
@button-text-color: #fff;

.responsive-layout {
    width:100%;
    margin: 0 auto;
    @media all and (min-width:520px) {
        width:520px;
    }
    @media all and (min-width:760px) {
        width:100%;
    }
    @media all and (min-width:960px) {
        width:960px;
    }
}

.visibility-hidden {
    visibility:hidden!important;
}

.display-none {
    display:none!important;
}

.accessibility {
    :focus {
        outline: @focus-outline;
    }
}

#accessibility-toggle {
    float:left;
    .dir-rtl & {
        float:right;
    }
    &:focus {
        border: @focus-outline;
        padding: 17px 20px;
    }
}

.skip-nav-link {
    background-color: @button-color;
    border-radius: 0;
    color: @button-text-color;
    display: none;
    left: 0;
    margin-top: 0;
    padding: 10px 20px;
    position: absolute;
    top: -46px;
    -webkit-transition: top .2s ease-out;
    transition: top .2s ease-out;
    width: auto;
    z-index: 100;
    .accessibility & {
        display: inline-block;
    }
    &:focus {
        top: 0;
    }
}

#wrapper {
    .responsive-layout;
    padding-top:40px;
}

.drawer {
    position:fixed;
    width:320px;
    height: 100%;
    top:0px;
    right:-320px;
    .dir-rtl & {
        right:inherit;
        left:-320px;
    }
    overflow-y: auto;
    overflow-x:hidden;
    -webkit-overflow-scrolling: touch;
    z-index:501;
    .drawer-back {
        float: left;
        .dir-rtl & {
            float:right;
        }
        &.show {
            display:inline-block;
        }
    }
    .drawer-close {
        float: right;
        .dir-rtl & {
            float: left;
        }
    }
    .drawer-inner {
        height:100%;
    }
    .drawer-item {
        display: block;
        width: 100%;
    }
    .drawer-item-open {
        display:block;
    }
}

.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    .dir-rtl & {
        left:auto;
        right:0;
    }
    z-index: 5000;
    .loading-image {
        width: 100%;
        height: 100px;
        position: absolute;
        top: 50%;
        margin-top: -50px;
    }
}

.navigation {
    .responsive-layout;
    position:fixed;
    top:0;
    height:40px;
    z-index:500;
    width:100%;

    .navigation-back-button {
        float:left;
        .dir-rtl & {
            float:right;
        }
    }
    .navigation-drawer-toggle-button {
        float:right;
        .dir-rtl & {
            float:left;
        }
    }
}

// Notify styling
// ==============

.notify {

    .notify-popup {
        position: fixed;
        top: 50%;
        width:100%;
        z-index: 550;
    }

    .notify-popup-icon {
        position: absolute;
        left: 0px;
        .dir-rtl & {
            left:inherit;
            right:0px;
        }
        top: 0px;
        .icon {
            @media all and (max-width:(@device-width-medium - 1px)) {
                float:none;
                margin: 0 auto;
            }
        }
        @media all and (max-width:(@device-width-medium - 1px)) {
            float:none;
            margin: 0px auto;
            position:static;
        }
    }

    .notify-popup-content {
        .responsive-layout
    }

    .notify-popup-content-inner {
        position:relative;
    }

    .notify-popup-done {
        display:inline-block;
    }

    .notify-shadow {
        display: none;
        position: fixed;
        top: 0px;
        left: 0px;
        .dir-rtl & {
            left:inherit;
            right:0px;
        }
        width: 100%;
        height: 100%;
        z-index: 549;
    }
}

.component-container:before, .component-container:after { content: "\0020"; display: block; height: 0; overflow: hidden; }
.component-container:after { clear: both; }
.component-container { zoom: 1; }

.component-left {
    width:100%;
    float:none;
    @media all and (min-width:(@device-width-medium + 1px)) {
        width:50%;
        float:left;
        .component-inner {
            margin-right:5%;
        }
    }
}

.component-right {
    width:100%;
    float:none;
    @media all and (min-width:(@device-width-medium + 1px)) {
        width:50%;
        float:right;
        .component-inner {
            margin-left:5%;
        }
    }
}

/*TO ALLOW CHILDREN ELEMENTS TO BE POSITIONED ABSOLUTELY*/
.component { position:relative; }
.block { position: relative; }

//jquery.a11y start
/*INVISIBLE ARIA-LABELS*/
.aria-label {
    position:absolute !important;
    &.relative {
        position:relative !important;
    }
    left:0px !important;
    width:auto !important;
    height:auto !important;
    overflow:auto !important;
    color: rgba(0,0,0,0) !important;
    background: rgba(0,0,0,0) !important;
    font-size: 1px !important;
    padding:0 !important;
    margin:0 !important;
    line-height: normal !important;
    z-index:1;
}

    /*FORCE ARIA-LABELS TO HIDE ON HIDDEN*/
    .aria-label.aria-hidden {
        display:none !important;
    }

/*HIDDEN FOCUS GUARD*/
.a11y-focusguard {
    left:0px !important;
    bottom:0px !important;
    width:auto !important;
    height:auto !important;
    overflow:auto !important;
    color: rgba(0,0,0,0) !important;
    background: rgba(0,0,0,0) !important;
    font-size: 1px !important;
    padding:0 !important;
    margin:0 !important;
    line-height: normal !important;
    z-index:1;

    /*IPAD: Stop accessibility cursor focusing until end of page;*/
    .touchevents & {
        position: relative !important;
    }

    /*DESTKOP: stop focus making window jump to bottom of page;*/
    .no-touchevents & {
        position: fixed !important;
    }
}

/*HIDDEN FOCUS/ALERT ELEMENT */
#a11y-selected, #a11y-selected * {
    position:fixed !important;
    left:0px !important;
    bottom:0px !important;
    width:auto !important;
    height:auto !important;
    overflow:auto !important;
    color: rgba(0,0,0,0) !important;
    background: rgba(0,0,0,0) !important;
    font-size: 1px !important;
    padding:0 !important;
    margin:0 !important;
    line-height: normal !important;
    z-index:1;
}

/*FOCUS DISTRACTION*/
#a11y-focuser {
    position:fixed !important;
    left:0px !important;
    top:0px !important;
    width:auto !important;
    height:auto !important;
    overflow:auto !important;
    color: rgba(0,0,0,0) !important;
    background: rgba(0,0,0,0) !important;
    font-size: 1px !important;
    padding:0 !important;
    margin:0 !important;
    line-height: normal !important;
    z-index:1;
}


.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

//Default margin for HTML paragraph

body {
    p {
        margin-top: @body-text-font-size;
        margin-bottom: @body-text-font-size;
    }
}

// Used to wrap content which should have zero-width
// I<zw>&nbsp;</zw>T   will look like IT but read 'EYE TEA' instead of 'it'
zw {
    display: inline-block;
    width: 0;
}

// Used to wrap content which should not break across multiple lines
// <nw>I<zw>&nbsp;</zw>T</nw>   will not split to I
// T instead it will allways appear as IT
nb {
    white-space: nowrap;
}
