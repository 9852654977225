.trickle-button-component {
    .trickle-button-inner { //overwrite component styling 
        background-color: none;
        padding: 0;
    }

    &.trickle-round-arrow {
        button {
            background-color: @button-color;
            .transition-background-color
        }
    }

    &.trickle-full-width.trickle-round-arrow {
        .trickle-button-inner {
            &.locking {
                button {
                    background-color: @button-color;
                    .transition-background-color
                }
            }
        }
    }
}