.matching-component {

	.matching-item {
		padding-bottom: @item-padding-bottom;
	}

	.matching-item-title {
		margin-bottom: @item-margin-bottom;
	}

	.matching-select-container {
		position: relative;
		background-color: @item-color;

		&.error {
			/* outline doesn't work for this in Firefox (see #1682) */
			box-shadow: 0 0 0 1px @validation-error;
			/* and because box-shadow isn't supported in IE8 */
			.ie8 & {
				outline: 1px solid @validation-error;
			}
		}
	}

	.matching-select-state {
		position: absolute;
		width: @icon-size;
		height: @icon-size;
		top: 50%;
		margin-top: -(@icon-size/2);
		right: 10px;
		background: none;

		.dir-rtl & {
			right: inherit;
			left: 10px;
		}

		.matching-select-icon {
			color: @item-text-color;
		}

		.matching-correct-icon {
			color: @validation-success;
			display: none;
		}

		.matching-incorrect-icon {
			color: @validation-error;
			display: none;
		}
	}

	.select2-container {

		&.select2-container--focus {
			.accessibility.no-touch & {
				outline: @focus-outline;
			}
		}
		
		.select2-selection--single {
			height: auto;
			padding: @item-padding-top 0 @item-padding-bottom 0;
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			border-radius: 0;
			background-color: @item-color;
			color: @item-text-color;


			&:hover {
				.no-touch & {
					background-color: @item-color-hover;
					color: @item-text-color-hover;
				}
			}

			.dir-rtl & {
				direction: rtl;
			}

			.select2-selection__rendered {
				line-height: inherit;
				padding-left: @item-padding-left;
				padding-right: @item-padding-right;
				color: @item-text-color;
			}

			.select2-selection__placeholder {
				color: @item-text-color;
			}

			.select2-selection__arrow {
				b {
					border-color: @item-text-color transparent transparent transparent;
				}

				width: @icon-size;
				height: @icon-size;
				top: 50%;
				margin-top: -(@icon-size/2);
				right: 10px;
				
				.dir-rtl & {
					right: inherit;
					left: 10px;
				}
			}
		}

		&.select2-container--open {
			&.select2-container--default {
				.select2-selection--single {
					.select2-selection__arrow {
						
						b {
							border-color: transparent transparent @item-text-color transparent;
						}
					
					}
				}
			}
		}

	}

	.matching-widget {

		&.submitted {
			.matching-select {
				cursor: default
			}
			.matching-select-container {
				background-color: @item-color-disabled;
			}

			.matching-select-state {
				z-index: 1;
			}
		}

		&.show-user-answer {
			.select2-selection__arrow,
			.matching-select-icon {
				display: none;
			}
			.incorrect {
				.matching-incorrect-icon {
					display: block;
				}
			}
			.correct {
				.matching-correct-icon {
					display: block;
				}
			}
		}

		&.show-correct-answer {
			.select2-selection__arrow,
			.matching-select-icon {
				display: none;
			}
			.incorrect .matching-correct-icon,
			.correct .matching-correct-icon {
				display: block;
			}
		}

	}

}

.no-touch {
	.matching-select-icon {
		&:hover {
			color: @item-text-color-hover;
		}
	}
	.matching-select-container {
		&:hover {
			color: @button-text-color-hover;
			background-color: @item-color-hover;
		}
	}
}

.ie8 {
	.matching-select-container {
		&.disabled {
			background-color: @item-color-disabled;
		}
	}
}

.select2-container--default,
.dir-rtl .select2-container--default {
	ul, li {
		margin: 0;
	}
	
	span.select2-dropdown {
		background-color: @item-color;
		color: @item-text-color;
	}

	.select2-results__option {
		padding-top: (@item-padding-top / 2);
		padding-bottom: (@item-padding-bottom / 2);
		padding-left: @item-padding-left;
		padding-right: @item-padding-right;
	}

	li.select2-results__option--highlighted[aria-selected],
	li.select2-results__option[aria-selected=true] {
		background-color: @item-color-hover;
		color: @item-text-hover-color;
	}

	&.select2-container--disabled {

		span.select2-selection--single,
		.no-touch & span.select2-selection--single:hover {
			background-color: @item-color-disabled;
			color: @item-text-disabled-color;
		}
	
	}
}

.accessibility.no-touch .matching-component .matching-widget.disabled .select2-container.select2-container--focus,
.accessibility.no-touch .matching-widget.disabled .select2.select2-container *:focus {
	outline: 0;
}

// fixes IE8 scrolling jump issue when item focused 
.select2-search__field {
	position: fixed;
}
