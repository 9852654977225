// Additional theme classes or attributes to be applied in JSON for more custom style
// ====================================================================

// Divider block - used to separate content or break up pages.
// =============

// Class applies alternative background color and component styling

.divider-block {
    background-color: @background-color-inverted;
    color: @foreground-color-inverted;
    text-align: center;
    .block-title,
    .block-body,
    .block-instruction,
    .component-title,
    .component-body,
    .component-instruction {
    	color: @foreground-color-inverted;
    	text-align: center;
    }
    .component-inner {
    	background-color: transparent;
    }
}

// Block background color - used to apply variable based background colors on blocks
// ======================

.block {
	&.primary-color {
		background-color: @primary-color;
	}

	&.secondary-color {
		background-color: @secondary-color;
	}

	&.tertiary-color {
		background-color: @tertiary-color;
	}

	&.background-color {
		background-color: @background-color;
	}

	&.background-color-inverted {
		background-color: @background-color-inverted;
		color: @font-color-inverted;
		.component-inner {
			background-color: transparent;
		}
	}
}

// Component background - used to apply variable based background color and padding on components
// ====================

.component-background {
	background-color: @component-background-color;
	padding: @component-padding;
}

// Assessment - remove graphic components for mobile view (when components snap down to single span).
// ==========

.assessment {
	.graphic-component {
		display: block;

		@media all and (max-width: @device-width-medium) {
			display: none;
		}
	}
}

// THIS CLASS IS FOR THE PURPOSE OF THE ADAPT DEMO
// blank component demo - applied at block level to add a background image in place of a blank component
// ====================

.blank-background {
	background-image: url(assets/blank-bg.jpg);
	background-repeat: no-repeat;
	background-position: center;

	@media all and (max-width: @device-width-large) {
		background-image: url(assets/blank-bg-900.jpg);
		background-position: right;
	}

	@media all and (max-width: @device-width-medium) {
		background-image: none;
	}
}

.b-100 {
  .component  {
    .component-title {
      color: @white;
    }
  }  
}
