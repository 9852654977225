.resources {
    .resources-filter button {
        display:block;
        float:left;
	    .dir-rtl & {
	        float:right;
	    }
        text-align:center;
        text-decoration: none;
        color:@item-text-color;
        border-bottom: 1px solid @drawer-item-color;
        span {
            width:100%;
            display:inline-block;
            padding:10px 0px;
            border-right: 1px solid @drawer-item-color;
		    .dir-rtl & {
		        border-left: 1px solid @drawer-item-color;
		        border-right: inherit;
		    }
        }
        &.selected {
            color:@drawer-item-text-hover-color;
            background-color: @drawer-item-hover-color;
        }
        .no-touch &:hover {
            color:@drawer-item-text-hover-color;
            background-color: @drawer-item-hover-color;
        }
    }
    .resources-col-4 {
        button {
            width:25%;
        }
        
    }
    .resources-col-3 {
        button {
            width:33.3%;
        }
        
    }
    
}

