.gmcq-component {
    .gmcq-item {
        @media all and (max-width:@device-width-medium) {
            width:100%;
            &.even, &.odd {
                label {
                    margin-left:0%;
                    margin-right:0%;
                }
            }
        }
    }

    &.col-4 {
        .gmcq-item {
            width: 25%;

            &.item-1, &.item-2 {
                label {
                    margin-left: 2.5%;
                    margin-right: 2.5%;
                }
            }
        
            @media all and (max-width:@device-width-medium) {
                width:100%;
                &.even, &.odd {
                    label {
                        margin-left:0%;
                        margin-right:0%;
                    }
                }
            }
        }
    }

    .gmcq-item label {
        color: @item-text-color;
        border: none;
        background-color: transparent;

        .gmcq-answer-icon {
            color: @item-color;
        }

        &.selected {
            color: @item-color-selected;
            background-color: transparent;
            
            .gmcq-item-icon {
                color: @item-color-selected;

                &.gmcq-correct-icon {
                    color:@validation-success;
                }

                &.gmcq-incorrect-icon {
                    color:@validation-error;
                }
            }
            
            .gmcq-item-inner {
                color: @item-color-selected;
            }
        }

        &.disabled {
            .gmcq-item-icon {
                color: @item-color-disabled;
            }
            .gmcq-item-inner {
                color: @item-color-disabled;
            }
        }
    }

    .gmcq-item-inner {
        margin-left: @icon-size * 2;
        color: @item-color;
        padding-bottom: @item-padding-bottom;
        padding-top: @item-padding-top;
        padding-left: 0;
        padding-right: 0;
        .dir-rtl & {
            margin-left: inherit;
            margin-right: @icon-size * 2;
        }
    }

    .gmcq-item input {
        filter: alpha(opacity=0); // ie8 fix for opacity on input
        opacity: 0;
    }

    .gmcq-correct-icon {
        color: @validation-success;
    }

    .gmcq-incorrect-icon {
        color: @validation-error;
    }

    .gmcq-widget {
        &.show-user-answer {
            label.selected {
                .gmcq-item-inner {
                    color: @item-color-selected;
                }
            }
        }

        &.show-correct-answer {
            label.selected {
                .gmcq-item-inner {
                    color: @item-color-selected;
                }
            }
        }

        &.disabled {
            .gmcq-item label.selected.disabled {
                background-color: transparent;
                .gmcq-item-icon {
                    &.gmcq-correct-icon {
                        color:@validation-success;
                    }
                    &.gmcq-incorrect-icon {
                        color:@validation-error;
                    }
                }
            }

            .gmcq-item label.disabled {
                color: @item-color-disabled;
                background-color: transparent;
                border-color: @item-border-color-disabled;
                border: none;
            }
        }
    }
}

.no-touch {
    .gmcq-component {
        .gmcq-widget {
            &:not(.disabled) {
                .gmcq-item label:hover {
                    color: @item-color-hover;
                    background-color: transparent;
                    .gmcq-item-icon {
                        color: @item-color-hover;
                    }
                    .gmcq-item-inner {
                        color: @item-color-hover;
                    }
                }
            }
        }
    }
}