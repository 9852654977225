.accordion-component {

    .accordion-item {
        margin-bottom: @item-margin-bottom;
    }

    .accordion-item-title-icon {
        position: absolute;
        top: 50%;
        left: @item-padding-left;
        margin-top: -(@icon-size/2);
        color: @item-text-color;
        .dir-rtl & {
            left: inherit;
            right: @item-padding-left; 
        }
    }

    .accordion-item-title {
        display: block;
        position: relative;
        padding: @item-padding;
        padding-left: (@icon-size+(@item-padding-left*2));
        color: @item-text-color;
        background-color: @item-color;
        text-decoration: none;
        width: 100%;
        .dir-rtl & {
            padding-left:inherit;
            padding-right:(@icon-size+(@item-padding-left*2));
        }

        &.visited {
            color:@item-text-color-visited;
            background-color: @item-color-visited;
            border-color: @item-border-color-visited;

            .accordion-item-title-icon {
                color: @item-text-color-visited;
            }
        }

        &.selected {
            color: @item-text-color-selected;
            background-color: @item-color-selected;
            border-color: @item-border-color-selected;

            .accordion-item-title-icon {
                color: @item-text-color-selected;
            }
        }
        
        .no-touch & {
            &:hover {
                color: @item-text-color-hover;
                background-color: @item-color-hover;
                border-color: @item-border-color-hover;

                .accordion-item-title-icon {
                    color: @item-text-color-hover;
                }
            }
            &.visited:hover {
                color: @item-text-color-hover;
                background-color: @item-color-hover;
                border-color: @item-border-color-hover;

                .accordion-item-title-icon {
                    color: @item-text-color-hover;
                }
            }
            &.selected:hover {
                background-color: @item-color-hover;
            }
        }
    }

    .accordion-item-body {
        color: @item-body-text-color;
        background-color: @item-body-color;
        display:none;
    }

    .accordion-item-body-inner {
        padding: @item-padding;
    }

    .accordion-item-graphic {
        padding: @item-padding-top 0 0;

        img {
            vertical-align: bottom;
        }
    }
    
    .graphic-attribution {
        font-size:0.75em;
        line-height: 1em;
    }
}
