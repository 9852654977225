// Shadow styling
// ==============

.shadow {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 500;
    background-color: @transparency;

    .no-opacity & {
        background-image: @transparency-fallback-png;
    }
}