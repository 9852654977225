.boxmenu-menu {
	background: @primary-color;
	.menu-container {
		.responsive-large;
		.responsive-medium;
		.responsive-small;
	}

	.menu-container-inner {
		padding:@menu-body-padding;
	}

	.menu-title, .menu-body{
		color: @inverted-foreground-color;
		margin-bottom:@menu-title-padding-bottom;
	}

	.menu-item {
		.responsive-medium;
		.responsive-small;
		width:50%;
		float:left;
		.dir-rtl & {
			float:right;
		}
		margin-top:@menu-body-padding-bottom;
		margin-bottom:20px;


		&.nth-child-even{
			.menu-item-inner{
				margin-left:5%;
				.dir-rtl & {
					margin-left:inherit;
					margin-right:5%;
				}
			}
		}

		&.nth-child-odd {
			clear: both;
			
			.menu-item-inner {
				margin-right:5%;
				.dir-rtl & {
					margin-right:inherit;
					margin-left:5%;
				}
			}
		}
	}

	.menu-item-inner {
		background: @background-color;
		padding-bottom:20px;
		position:relative;
	}

	.menu-graphic a {
		display: block;
	}
	
	.menu-item-title {
		padding:@menu-body-padding;
	}

	.menu-item-body {
		padding:@menu-body-padding;
	}

	.menu-item-button {
		padding:@menu-body-padding;
		
		a {
			text-decoration: none;
			background-color:@button-color;
			color:@button-text-color;
			padding:@button-padding;
			
			&:hover {
				background-color:@button-hover-color;
				color:@button-text-hover-color;
			}
			
			&.visited {
				background-color:@button-disabled-color;
				color:@button-text-disabled-color;
			}
		}
	}

	.menu-item-duration {
		float:right;
		.dir-rtl & {
			float:left;
		}
	}
}



@media all and (max-width:@device-width-medium) {

	.boxmenu-menu {

		.menu-inner {
			padding:0;
		}

		.menu-title-inner{
			padding:15px 0 0;
		}

		.menu-body-inner{
			padding:0px 0 10px;
		}

		.menu-item{
			width:100%;
			margin-bottom:20px;
			
			&.nth-child-even, &.nth-child-odd{
				
				.menu-item-inner{
					margin:0;
				}
			}
		}
	}
}
