// Icons
// =====

@font-face {
	font-family: 'vanilla';
	src: url(fonts/vanilla.woff);
}

.icon {
	font-family: 'vanilla';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon {
    color: @foreground-color;
    display: block;
    font-size: @icon-size;
    text-decoration: none;
    text-align: center;
}

.icon-arrow-left:before {
	content: "\e600";
    .dir-rtl & {
        content: "\e603";
    }
}
.icon-arrow-down:before {
	content: "\e601";
}
.icon-arrow-up:before {
	content: "\e602";
}
.icon-arrow-right:before {
	content: "\e603";
    .dir-rtl & {
        content: "\e600";
    }
}
.icon-bookmark:before {
	content: "\e604";
}
.icon-message:before {
	content: "\e605";
}
.icon-mouse:before {
	content: "\e606";
}
.icon-mobile:before {
	content: "\e607";
}
.icon-desktop:before {
	content: "\e608";
}
.icon-pencil:before {
	content: "\e609";
}
.icon-forward-arrow:before {
	content: "\e60a";
    .dir-rtl & {
        content: "\e60b";
    }
}
.icon-backwards-arrow:before {
	content: "\e60b";
    .dir-rtl & {
        content: "\e60a";
    }
}
.icon-person:before {
	content: "\e60c";
}
.icon-persons:before {
	content: "\e60d";
}
.icon-profile:before {
	content: "\e60e";
}
.icon-pin:before {
	content: "\e60f";
}
.icon-map:before {
	content: "\e610";
}
.icon-point:before {
	content: "\e611";
}
.icon-star:before {
	content: "\e612";
}
.icon-star-hollow:before {
	content: "\e613";
}
.icon-comments:before {
	content: "\e614";
}
.icon-comment:before {
	content: "\e615";
}
.icon-home:before {
	content: "\e616";
}
.icon-popup:before {
	content: "\e617";
}
.icon-search:before {
	content: "\e618";
}
.icon-printer:before {
	content: "\e619";
}
.icon-link:before {
	content: "\e61a";
}
.icon-flag:before {
	content: "\e61b";
}
.icon-cog:before {
	content: "\e61c";
}
.icon-tools:before {
	content: "\e61d";
}
.icon-tag:before {
	content: "\e61e";
}
.icon-camera:before {
	content: "\e61f";
}
.icon-feedback:before {
	content: "\e620";
}
.icon-book:before {
	content: "\e621";
}
.icon-article:before {
	content: "\e622";
}
.icon-eye:before {
	content: "\e623";
}
.icon-time:before {
	content: "\e624";
}
.icon-microphone:before {
	content: "\e625";
}
.icon-calendar:before {
	content: "\e626";
}
.icon-timer:before {
	content: "\e627";
}
.icon-drag:before {
	content: "\e628";
}
.icon-light-bulb:before {
	content: "\e629";
}
.icon-checklist:before {
	content: "\e62a";
}
.icon-ticket:before {
	content: "\e62b";
}
.icon-wi-fi:before {
	content: "\e62c";
}
.icon-line-graph:before {
	content: "\e62d";
}
.icon-pie-chart:before {
	content: "\e62e";
}
.icon-bar-graph:before {
	content: "\e62f";
}
.icon-scatter-graph:before {
	content: "\e630";
}
.icon-padlock-locked:before {
	content: "\e631";
}
.icon-padlock-unlocked:before {
	content: "\e632";
}
.icon-tick:before {
	content: "\e633";
}
.icon-cross:before {
	content: "\e634";
}
.icon-minus:before {
	content: "\e635";
}
.icon-plus:before {
	content: "\e636";
}
.icon-info:before {
	content: "\e637";
}
.icon-question:before {
	content: "\e638";
}
.icon-warning:before {
	content: "\e639";
}
.icon-list:before {
	content: "\e63a";
}
.icon-add-to-list:before {
	content: "\e63b";
}
.icon-menu:before {
	content: "\e63c";
}
.icon-grid:before {
	content: "\e63d";
}
.icon-portrait:before {
	content: "\e63e";
}
.icon-screens:before {
	content: "\e63f";
}
.icon-landscape:before {
	content: "\e640";
}
.icon-images:before {
	content: "\e641";
}
.icon-media:before {
	content: "\e642";
}
.icon-folder:before {
	content: "\e643";
}
.icon-documents:before {
	content: "\e644";
}
.icon-delete:before {
	content: "\e645";
}
.icon-save:before {
	content: "\e646";
}
.icon-download:before {
	content: "\e647";
}
.icon-upload:before {
	content: "\e648";
}
.icon-expand:before {
	content: "\e649";
}
.icon-shrink:before {
	content: "\e64a";
}
.icon-volume:before {
	content: "\e64b";
}
.icon-sound:before {
	content: "\e64c";
}
.icon-sound-mute:before {
	content: "\e64d";
}
.icon-controls-left:before {
	content: "\e64e";
    .dir-rtl & {
        content: "\e651";
    }
}
.icon-controls-down:before {
	content: "\e64f";
}
.icon-controls-up:before {
	content: "\e650";
}
.icon-controls-right:before {
	content: "\e651";
    .dir-rtl & {
        content: "\e64e";
    }
}
.icon-triangle-left:before {
	content: "\e652";
    .dir-rtl & {
        content: "\e655";
    }
}
.icon-triangle-down:before {
	content: "\e653";
}
.icon-triangle-up:before {
	content: "\e654";
}
.icon-triangle-right:before {
	content: "\e655";
    .dir-rtl & {
        content: "\e652";
    }
}
.icon-dot:before {
	content: "\e656";
}
.icon-ellipsis:before {
	content: "\e657";
}
.icon-up-down:before {
	content: "\e658";
}
.icon-controls-small-left:before {
	content: "\e659";
    .dir-rtl & {
        content: "\e65c";
    }
}
.icon-controls-small-down:before {
	content: "\e65a";
}
.icon-controls-small-up:before {
	content: "\e65b";
}
.icon-controls-small-right:before {
	content: "\e65c";
    .dir-rtl & {
        content: "\e659";
    }
}
.icon-radio-checked:before {
	content: "\e65d";
}
.icon-radio-unchecked:before {
	content: "\e65e";
}
.icon-language-1:before {
	content: "\e900";
}
.icon-accessibility:before {
	content: "\e901";
}
.icon-globe:before {
	content: "\f0ac";
}
.icon-language-2:before {
	content: "\f1ab";
}
