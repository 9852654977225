.mcq-component {
    .mcq-item {
        background-color:@item-color;
        position:relative;
    }

    .mcq-item label {
        color:@item-text-color;
        display:block;
        margin-bottom:@item-margin-bottom;
        position:relative;
        z-index:1;
        background-color:@item-color;
        cursor:pointer;

        &.selected {
            color:@item-text-color-selected;
            background-color:@item-color-selected;
            border-color:@item-border-color-selected;
            .mcq-answer-icon {
                color:@item-text-color-selected;

                 &.radio {
                    &:before {
                        content: "\e65d";
                    }
                }
                &.checkbox {
                    &:before {
                        content: "\e633";
                    }
                    border: 3px solid @item-text-color-selected;
                    width:18px;
                    height:18px;
                    font-size:18px;
                    font-weight: bold;
                    border-radius: 2px;
                }
            }
            .mcq-item-icon {
                color:@item-text-color-selected;

                &.mcq-correct-icon {
                    color:@validation-success;
                }

                &.mcq-incorrect-icon {
                    color:@validation-error;
                }
            }
        }
    }

    .mcq-item-inner {
        padding-top: @item-padding-top;
        padding-bottom: @item-padding-bottom;
        padding-right: @item-padding-right;
        padding-left: (@icon-size+(@item-padding-left*2));
    }

    .mcq-correct-icon {
        display:none;
    }

    .mcq-incorrect-icon {
        display:none;
    }

    .mcq-item-state {
        background:none;
        position:absolute;
        left:@item-padding-left;
        .dir-rtl & {
            left:inherit;
            right:@item-padding-left;
        }
        width:@icon-size;
        height:@icon-size;
        top:50%;
        margin-top:-(@icon-size/2);
        z-index:0;
    }
}

.mcq-widget {

    &.disabled {
        .mcq-item label.selected.disabled {
            color:@item-text-color-selected;
            background-color:@item-color-selected;
            border-color:@item-border-color-selected;
            cursor:default;
        }
        .mcq-item label.disabled {
            color:@item-text-color-disabled;
            background-color:@item-color-disabled;
            border-color:@item-border-color-disabled;
            cursor:default;

            .mcq-item-icon {
                &.mcq-correct-icon {
                    color:@validation-success;
                }
                &.mcq-incorrect-icon {
                    color:@validation-error;
                }
            }
        }
    }
}

.no-touch {

    .mcq-widget {
        &:not(.disabled) {
            .mcq-item label:hover {
                color:@item-text-color-hover;
                background-color:@item-color-hover;
                border-color:@item-border-color-hover;
                .mcq-item-icon {
                    color: none;
                }
            }
            .mcq-item label.disabled {
                color:@item-text-color-disabled;
                background-color:@item-color-disabled;
                border-color:@item-border-color-disabled;
                &:hover {
                    color:@item-text-color-disabled;
                    background-color:@item-color-disabled;
                    border-color:@item-border-color-disabled;
                }
                .mcq-item-icon {
                    &.mcq-correct-icon {
                        color:@validation-success;
                    }
                    &.mcq-incorrect-icon {
                        color:@validation-error;
                    }
                }
            }
        }
    }
}